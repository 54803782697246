<template>
  <div class="user-list">
    <el-card class="box-card">
      <!-- 搜索栏 -->
      <div class="search-bar">
        <el-form 
          :inline="!isMobile" 
          :model="searchForm" 
          class="form-inline"
          :label-position="isMobile ? 'top' : 'right'"
          size="medium"
        >
          <el-form-item label="用户名">
            <el-input v-model="searchForm.username" placeholder="请输入用户名" clearable></el-input>
          </el-form-item>
          <el-form-item label="邀请人">
            <el-input v-model="searchForm.inviterUsername" placeholder="请输入邀请人用户名" clearable></el-input>
          </el-form-item>
          <el-form-item class="search-buttons">
            <el-button type="primary" @click="handleSearch" :loading="loading">查询</el-button>
            <el-button @click="resetSearch">重置</el-button>
            <el-button 
              type="success" 
              icon="el-icon-refresh" 
              :loading="loading"
              @click="refreshData"
            >刷新</el-button>
          </el-form-item>
        </el-form>
      </div>

      <!-- PC端表格 -->
      <el-table
        v-if="!isMobile"
        :data="tableData"
        style="width: 100%"
        v-loading="loading"
      >
        <el-table-column prop="userId" label="用户ID" width="80"></el-table-column>
        <el-table-column prop="username" label="用户名" width="120"></el-table-column>
        <el-table-column prop="balance" label="账户余额" width="120">
          <template slot-scope="scope">
            {{ scope.row.balance.toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column prop="isVip" label="代理级别" width="100">
          <template slot-scope="scope">
            <el-tag :type="getAgentLevelType(scope.row.isVip)">
              {{ getAgentLevelText(scope.row.isVip) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="registerTime" label="注册时间" width="160">
          <template slot-scope="scope">
            {{ formatDate(scope.row.registerTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="inviterName" label="邀请人" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.inviterName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <el-button size="mini" type="text" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button 
              size="mini" 
              type="text"
              class="text-danger"
              @click="handleDelete(scope.row)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 移动端卡片列表 -->
      <div v-else class="mobile-user-list">
        <el-card 
          v-for="item in tableData" 
          :key="item.userId" 
          class="user-card"
          shadow="hover"
        >
          <div class="user-card-header">
            <span class="user-id">ID: {{ item.userId }}</span>
            <el-tag :type="getAgentLevelType(item.isVip)">
              {{ getAgentLevelText(item.isVip) }}
            </el-tag>
          </div>
          
          <div class="user-card-content">
            <div class="info-item">
              <span class="label">用户名：</span>
              <span class="value">{{ item.username }}</span>
            </div>
            <div class="info-item">
              <span class="label">账户余额：</span>
              <span class="value">{{ item.balance.toFixed(2) }}</span>
            </div>
            <div class="info-item">
              <span class="label">注册时间：</span>
              <span class="value">{{ formatDate(item.registerTime) }}</span>
            </div>
            <div class="info-item">
              <span class="label">邀请人：</span>
              <span class="value">
                {{ item.inviterName }}
              </span>
            </div>
          </div>

          <div class="user-card-actions">
            <el-button 
              type="primary" 
              size="small" 
              @click="handleEdit(item)"
            >编辑</el-button>
            <el-button 
              type="danger" 
              size="small" 
              @click="handleDelete(item)"
            >删除</el-button>
          </div>
        </el-card>
      </div>

      <!-- 分页 -->
      <div class="pagination-container">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :layout="isMobile ? 'prev, pager, next' : 'total, sizes, prev, pager, next, jumper'"
          :total="total"
          :pager-count="isMobile ? 5 : 7"
        >
        </el-pagination>
      </div>
    </el-card>

    <!-- 编辑用户对话框 -->
    <el-dialog
      title="编辑用户"
      :visible.sync="dialogVisible"
      :width="isMobile ? '90%' : '500px'"
      @close="resetForm"
      custom-class="edit-dialog"
    >
      <el-form :model="editForm" :rules="rules" ref="editForm" label-width="100px">
        <el-form-item label="用户名" prop="username">
          <el-input v-model="editForm.username" disabled></el-input>
        </el-form-item>
        <el-form-item label="代理级别" prop="isVip">
          <el-select v-model="editForm.isVip" placeholder="请选择代理级别">
            <el-option label="普通用户" :value="0"></el-option>
            <el-option label="一级代理" :value="1"></el-option>
            <el-option label="二级代理" :value="2"></el-option>
            <el-option label="三级代理" :value="3"></el-option>
            <el-option label="管理员" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="登录密码" prop="password">
          <el-input 
            v-model="editForm.password" 
            type="password" 
            show-password
            placeholder="不修改请留空"
          ></el-input>
        </el-form-item>
        <el-form-item label="资金密码" prop="payPassword">
          <el-input 
            v-model="editForm.payPassword" 
            type="password" 
            show-password
            placeholder="不修改请留空"
          ></el-input>
        </el-form-item>
        <el-form-item label="账户余额" prop="balance">
          <el-input-number 
            v-model="editForm.balance"
            :precision="2"
            :step="100"
            :min="0"
            controls-position="right"
            style="width: 100%"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitEdit" :loading="submitting">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'UserList',
  data() {
    return {
      isMobile: false,
      searchForm: {
        username: '',
        inviterUsername: ''
      },
      tableData: [],
      loading: false,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      dialogVisible: false,
      submitting: false,
      editForm: {
        userId: '',
        username: '',
        password: '',
        payPassword: '',
        isVip: 0,
        balance: ''
      },
      rules: {
        password: [
          { min: 6, message: '密码长度不能小6位', trigger: 'blur' }
        ],
        payPassword: [
          { min: 6, message: '资金密码长度不能小于6位', trigger: 'blur' }
        ],
        isVip: [
          { required: true, message: '请选择代理级别', trigger: 'change' }
        ]
      }
    }
  },
  created() {
    this.checkDevice()
    window.addEventListener('resize', this.checkDevice)
    this.fetchData()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkDevice)
  },
  methods: {
    checkDevice() {
      this.isMobile = window.innerWidth < 768
    },
    async fetchData() {
      if (this.loading) return // 防止重复请求
      
      this.loading = true
      try {
        const params = {
          page: this.currentPage,
          pageSize: this.pageSize,
          ...this.searchForm
        }
        const res = await this.$http.get('/api/admin/users', { params })
        if (res.data.code === 200) {
          this.tableData = res.data.data.list
          this.total = res.data.data.total
        }
      } catch (error) {
        console.error('获取用户列表失败:', error)
        this.$message.error('获取用户列表失败')
      } finally {
        this.loading = false
      }
    },
    formatDate(timestamp) {
      const date = new Date(timestamp)
      return date.toLocaleString()
    },
    handleSearch() {
      this.currentPage = 1
      this.fetchData()
    },
    resetSearch() {
      this.searchForm = {
        username: '',
        inviterUsername: ''
      }
      this.handleSearch()
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.fetchData()
    },
    handleEdit(row) {
      this.editForm = { ...row }
      this.dialogVisible = true
    },
    getAgentLevelType(level) {
      const types = {
        0: 'info',
        1: 'success',
        2: 'warning',
        3: 'danger',
        4: 'primary'
      }
      return types[level] || 'info'
    },
    getAgentLevelText(level) {
      const texts = {
        0: '普通用户',
        1: '一级代理',
        2: '二级代理',
        3: '三级代理',
        4: '管理员'
      }
      return texts[level] || '普通用户'
    },
    resetForm() {
      this.$refs.editForm?.resetFields()
      this.editForm = {
        userId: '',
        username: '',
        password: '',
        payPassword: '',
        isVip: 0,
        balance: ''
      }
    },
    async submitEdit() {
      try {
        await this.$refs.editForm.validate()
        this.submitting = true

        // 只发送修改的字段
        const updateData = {
          isVip: this.editForm.isVip,
          balance: this.editForm.balance
        }
        if (this.editForm.password) {
          updateData.password = this.editForm.password
        }
        if (this.editForm.payPassword) {
          updateData.payPassword = this.editForm.payPassword
        }

        const res = await this.$http.put(
          `/api/admin/users/${this.editForm.userId}`, 
          updateData
        )

        if (res.data.code === 200) {
          this.$message.success('修改成功')
          this.dialogVisible = false
          this.fetchData()
        }
      } catch (error) {
        console.error('修改用户信息失败:', error)
        this.$message.error('修改失败')
      } finally {
        this.submitting = false
      }
    },
    // 刷新数据方法
    refreshData() {
      // 直接调用获取数据的方法，保持当前搜索条件和分页状态
      this.fetchData()
    },
    // 添加删除方法
    async handleDelete(row) {
      try {
        await this.$confirm('确认删除该用户吗？此操作不可恢复！', '警告', this.getConfirmConfig('确认删除该用户吗？此操作不可恢复！', '警告'))
        const res = await this.$http.delete(`/api/admin/users/${row.userId}`)
        
        if (res.data.code === 200) {
          this.$message.success('删除成功')
          this.fetchData()
        }
      } catch (error) {
        if (error !== 'cancel') {
          console.error('删除用户失败:', error)
          this.$message.error('删除失败')
        }
      }
    },
    getConfirmConfig(message, title = '提示') {
      return {
        title,
        message,
        customClass: 'mobile-confirm-dialog',
        center: this.isMobile,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        confirmButtonClass: this.isMobile ? 'confirm-button' : '',
        cancelButtonClass: this.isMobile ? 'cancel-button' : '',
        type: 'warning'
      }
    }
  }
}
</script>

<style scoped>
.user-list {
  padding: 20px;
}

.search-bar {
  margin-bottom: 20px;
}

.pagination-container {
  margin-top: 20px;
  text-align: right;
}

.text-danger {
  color: #F56C6C;
}

.text-success {
  color: #67C23A;
}

/* 移动端样式 */
@media screen and (max-width: 768px) {
  .user-list {
    padding: 12px;
  }

  .search-bar {
    margin-bottom: 12px;
  }

  .form-inline :deep(.el-form-item) {
    margin-bottom: 12px;
    width: 100%;
  }

  .form-inline :deep(.el-form-item__label) {
    padding-bottom: 4px;
    line-height: 1.2;
  }

  .form-inline :deep(.el-form-item__content) {
    width: 100%;
  }

  .search-buttons {
    display: flex;
    justify-content: flex-end;  /* 改为靠右对齐 */
    margin-bottom: 0;
    gap: 8px;  /* 按钮之间的间距 */
  }

  .search-buttons :deep(.el-button) {
    min-width: auto;  /* 移除最小宽度限制 */
    padding: 8px 12px;  /* 设置合适的内边距 */
  }

  .mobile-user-list {
    margin: 0 -12px;
  }

  .user-card {
    margin-bottom: 8px;
    border-radius: 0;
  }

  .user-card :deep(.el-card__body) {
    padding: 12px;
  }

  .user-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }

  .user-id {
    font-size: 14px;
    color: #909399;
  }

  .info-item {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 1.4;
  }

  .info-item .label {
    color: #909399;
  }

  .user-card-actions {
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;  /* 按钮之间的间距 */
  }

  .user-card-actions :deep(.el-button) {
    margin-left: 0;  /* 移除默认的按钮间距 */
  }

  .pagination-container {
    padding: 0 12px;
  }

  .form-inline :deep(.el-form-item.search-buttons) {
    margin-bottom: 0;
    display: flex;
    justify-content: flex-end;  /* 让整个按钮组靠右 */
    width: 100%;
  }

  .search-buttons {
    display: flex;
    gap: 8px;
  }

  .search-buttons :deep(.el-button) {
    padding: 8px 12px;
  }

  .info-item .value {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  /* 增加下拉菜单选项的高度和间距 */
  :deep(.el-select-dropdown__item) {
    height: 40px;  /* 增加选项高度 */
    line-height: 40px;  /* 文字垂直居中 */
    padding: 0 20px;  /* 增加左右内边距 */
  }

  /* 确保下拉框有足够的点击区域 */
  :deep(.el-select .el-input__inner) {
    height: 40px;  /* 增加输入框高度 */
    line-height: 40px;
  }

  /* 调整下拉菜单的最大高度，确保能显示所有选项 */
  :deep(.el-select-dropdown) {
    max-height: 300px;
  }
}

/* 暗色主题适配 */
@media (prefers-color-scheme: dark) {
  .user-card {
    background-color: #1f2d3d;
    border-color: #304156;
  }

  .user-id {
    color: #a6a9ad;
  }

  .info-item .label {
    color: #a6a9ad;
  }

  .edit-dialog {
    background-color: #1f2d3d;
  }

  .edit-dialog :deep(.el-dialog__title) {
    color: #fff;
  }

  .edit-dialog :deep(.el-dialog__body) {
    color: #fff;
  }

  .edit-dialog :deep(.el-form-item__label) {
    color: #a6a9ad;
  }

  .edit-dialog :deep(.el-input__inner) {
    background-color: #304156;
    border-color: #304156;
    color: #fff;
  }

  .edit-dialog :deep(.el-input__inner:focus) {
    border-color: #409EFF;
  }
}

/* 超小屏幕额外优化 */
@media screen and (max-width: 375px) {
  .user-list {
    padding: 8px;
  }

  .info-item {
    font-size: 13px;
  }

  .user-card :deep(.el-card__body) {
    padding: 10px;
  }

  .user-card-actions .el-button {
    padding: 8px 12px;
    font-size: 13px;
  }

  .search-buttons :deep(.el-button) {
    min-width: 0;  /* 覆盖之前的样式 */
  }
}

.el-tag--mini {
  margin-left: 4px;
  height: 18px;
  line-height: 16px;
  padding: 0 4px;
}

/* 移动端样式调整 */
@media screen and (max-width: 768px) {
  .info-item .value {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

/* 移动端确认框样式 */
:deep(.mobile-confirm-dialog) {
  @media screen and (max-width: 768px) {
    width: 90% !important;
    margin-top: 15vh !important;
    
    .el-message-box__header {
      padding: 15px;
    }
    
    .el-message-box__content {
      padding: 15px;
    }
  }
}
</style> 